// @ts-nocheck
/* eslint-disable no-undef */
import useResizeObserver from 'hooks/useResizeObserver';
import { useEffect, useState } from 'react';

const useDrawerHeight = (filterDrawerOpen) => {
  const [setNode, entry] = useResizeObserver();
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!filterDrawerOpen) return;

    const drawerBodyElement = document.querySelector('.top-menu-drawer .ant-drawer-body');

    if (drawerBodyElement) {
      setNode(drawerBodyElement);
    }
  }, [filterDrawerOpen, setNode]);

  useEffect(() => {
    const currentHeight = entry?.contentRect?.height;
    const collapseItemLength = document
      .querySelectorAll('.ant-drawer-body .ant-collapse .ant-collapse-item')
      .length;

    if (currentHeight && collapseItemLength) {
      setHeight(currentHeight - (50 * collapseItemLength));
    }
  }, [entry]);

  return height;
};

export default useDrawerHeight;
