import React, { useEffect, useState } from 'react';
import {
  arrayOf, func, number, oneOfType, shape, string,
} from 'prop-types';
import ProductionCycles from './ProductionCycles';

const ProductionCyclesSettings = ({
  currentYear,
  currentSeason,
  productionCyclesBySeasons,
  checkedProdCycleKeys,
  setCheckedProdCycleKeys,
  drawerHeight,
}) => {
  const baseTreeData = productionCyclesBySeasons.filter(
    (item) => item.year.toString() === currentSeason.toString(),
  );
  const [treeData, setTreeData] = useState(baseTreeData);

  useEffect(() => {
    const currentTreeData = productionCyclesBySeasons.filter(
      (item) => item.year.toString() === currentSeason.toString(),
    );

    setTreeData(currentTreeData);

    if (currentSeason !== currentYear) {
      const checkedKeys = currentTreeData[0].children.map((item) => item.key);
      setCheckedProdCycleKeys(checkedKeys);
    }
  }, [currentSeason, currentYear, productionCyclesBySeasons, setCheckedProdCycleKeys]);

  return (
    <ul className="top-menu-field-groups-settings__production-cycles">
      <ProductionCycles
        checkedKeys={checkedProdCycleKeys}
        setCheckedKeys={setCheckedProdCycleKeys}
        treeData={treeData}
        drawerHeight={drawerHeight}
      />
    </ul>
  );
};

ProductionCyclesSettings.propTypes = {
  currentYear: oneOfType([number, string]).isRequired,
  currentSeason: oneOfType([number, string]).isRequired,
  productionCyclesBySeasons: arrayOf(shape({})),
  checkedProdCycleKeys: arrayOf(oneOfType([number, string])).isRequired,
  setCheckedProdCycleKeys: func.isRequired,
  drawerHeight: number.isRequired,
};

ProductionCyclesSettings.defaultProps = {
  productionCyclesBySeasons: [],
};

export default ProductionCyclesSettings;
