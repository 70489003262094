import React, {
  useCallback, useState, useMemo, useEffect, useRef,
} from 'react';
import {
  Alert, Button, Input, Spin, Tooltip, Tree,
} from 'antd';
import I18n from 'i18n-js';
import FaIcon from 'components/fa-icon';
import airbrakeClient from 'configuredAirbrakeClient';
import Item from 'components/top-menu-filter/nested/Item';
import prepareSearchApi from 'components/top-menu-filter/helpers/prepareSearchApi';
import setSearchInputFocus from 'components/top-menu-filter/helpers/setSearchInputFocus';
import modifyFilterListStyles from 'components/top-menu-filter/helpers/modifyFilterListStyles';
import { debounce } from 'lodash-es';
import {
  arrayOf, bool, number, object, oneOfType, string, shape, func, instanceOf,
} from 'prop-types';
import defineFilteredItems from 'components/top-menu-field-groups/helpers/groupabble_setting_helpers';
import { InfoIcon, PlayIcon, StopIcon } from 'components/cwo-icons';
import axios from 'axios';
import { work_recording_start_path, work_recording_stop_path } from 'js-routes/generated/routes';
import defineApplyRegionIds from '../machinery-regions-helpers/defineApplyRegionIds';
import pageReload from '../helpers/pageReload';
import DispatcherTimer from './DispatcherTimer';

const BASE_CLASS = 'top-menu-field-group-filter__machine-regions-filter';
const STATIC_OFFSET = 54;

const MachineRegionsSettings = ({
  checkedKeys,
  items,
  onCheck,
  onSelect,
  plainItemsIndex,
  selectedKeys,
  visible,
  expandAll,
  expandKeys,
  drawerHeight,
  dispatcherWorkRecord,
  saveFilterChanges,
  validateParams,
  dispatcherBlockRef,
}) => {
  const debounceTimeout = plainItemsIndex.size > 10000 ? 500 : 100;
  const filterWrapperRef = useRef(null);

  const [searchValue, setSearchValue] = useState('');
  const [searching, setSearching] = useState(false);
  const [filteredItems, setFilteredItems] = useState(items);
  const [workRecordChangesLoading, setWorkRecordChangesLoading] = useState(false);
  const searchIcon = searching ? <Spin size="small" /> : <FaIcon modifier="fal" icon="search" />;
  const checkable = onCheck instanceof Function;
  const selectable = onSelect instanceof Function;

  const searchApi = useMemo(() => prepareSearchApi(plainItemsIndex), [plainItemsIndex]);

  useEffect(() => {
    if (!visible) { return; }

    modifyFilterListStyles(filterWrapperRef.current);
    setSearchInputFocus(filterWrapperRef.current);
  }, [visible]);

  const settings = [
    searchApi, setFilteredItems, setSearching, items, plainItemsIndex,
  ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFilteredItems = useCallback(debounce(async (filter) => {
    try {
      setSearching(true);
      defineFilteredItems(filter, settings);
    } catch (error) {
      airbrakeClient.notify(error);
    }
  }, debounceTimeout), []);

  const onChangeSearchValue = useCallback((e) => {
    const { value } = e.target;
    setSearchValue(value);

    if (!value) { setFilteredItems(items); return; }
    updateFilteredItems(value);
  }, [updateFilteredItems, items]);

  const {
    showWorkRecord,
    initialSeconds,
    durationApproachingMessage,
  } = dispatcherWorkRecord;

  const saveWorkRecordChanges = async () => {
    setWorkRecordChangesLoading(true);

    try {
      if (!validateParams()) return;

      await saveFilterChanges();

      if (initialSeconds) {
        await axios.post(work_recording_stop_path(), {});
        pageReload();

        return;
      }

      await axios.post(
        work_recording_start_path(),
        // eslint-disable-next-line camelcase
        { machine_region_ids: defineApplyRegionIds(checkedKeys.checked, plainItemsIndex) },
      );

      pageReload();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      setWorkRecordChangesLoading(false);
    }
  };

  const dispatcherButtonOptions = {
    icon: initialSeconds ? <StopIcon /> : <PlayIcon />,
    color: initialSeconds ? 'danger' : 'primary',
    title: initialSeconds
      ? I18n.t('top_menu_right_part.dispatcher_mode.stop')
      : I18n.t('top_menu_right_part.dispatcher_mode.start'),
  };

  const dispatcherWorkOffset = showWorkRecord ? 62 + (durationApproachingMessage ? 76 : 0) : 0;
  const availableOffset = drawerHeight - (STATIC_OFFSET + dispatcherWorkOffset);

  return (
    <div className={BASE_CLASS} ref={filterWrapperRef}>
      {showWorkRecord && (
        <>
          <div ref={dispatcherBlockRef} className={`${BASE_CLASS}__dispatcher`}>
            <div className={`${BASE_CLASS}__dispatcher__title`}>
              <span>{I18n.t('top_menu_right_part.dispatcher_mode.label')}</span>
              <DispatcherTimer dispatcherWorkRecord={dispatcherWorkRecord} />
            </div>
            <Button
              className={`${BASE_CLASS}__dispatcher__${dispatcherButtonOptions.color}`}
              icon={dispatcherButtonOptions.icon}
              loading={workRecordChangesLoading}
              variant="outlined"
              size="small"
              color={dispatcherButtonOptions.color}
              onClick={saveWorkRecordChanges}
            >
              {dispatcherButtonOptions.title}
            </Button>
            <Tooltip title={I18n.t('top_menu_right_part.dispatcher_mode.info')}>
              <span className="top-menu-field-group-filter__info-icon"><InfoIcon /></span>
            </Tooltip>
          </div>
          {durationApproachingMessage && (
            <Alert
              message={durationApproachingMessage}
              style={{ marginBottom: '14px' }}
              type="warning"
            />
          )}
        </>
      )}
      <div className={`${BASE_CLASS}__search-container`}>
        <Input
          onChange={onChangeSearchValue}
          placeholder={I18n.t('search')}
          value={searchValue}
          suffix={searchIcon}
        />
      </div>

      <Tree
        style={{ minHeight: availableOffset }}
        checkable={checkable}
        checkedKeys={checkedKeys}
        checkStrictly
        height={availableOffset}
        defaultExpandAll={expandAll}
        defaultExpandedKeys={expandKeys}
        onCheck={onCheck}
        onSelect={onSelect}
        rootClassName="top-menu-field-group-filter__list"
        selectable={selectable}
        selectedKeys={selectedKeys}
        // @ts-ignore
        titleRender={Item}
        treeData={filteredItems}
      />
    </div>
  );
};

MachineRegionsSettings.propTypes = {
  onCheck: func,
  onSelect: func,
  checkedKeys: shape({
    checked: arrayOf(string),
    halfChecked: arrayOf(string),
  }),
  items: arrayOf(shape({
    active: bool,
    ancestorIds: arrayOf(number),
    // eslint-disable-next-line react/forbid-prop-types
    children: arrayOf(object),
    depth: number,
    hasSubfolders: bool,
    id: oneOfType([number, string]),
    key: string,
    title: string,
    type: string,
    selectedKeys: arrayOf(string),
  })),
  plainItemsIndex: instanceOf(Map),
  selectedKeys: arrayOf(string),
  visible: bool.isRequired,
  expandAll: bool,
  expandKeys: arrayOf(string),
  drawerHeight: number.isRequired,
  dispatcherWorkRecord: shape({
    showWorkRecord: bool,
    initialSeconds: number,
    durationApproachingMessage: string,
  }),
  saveFilterChanges: func,
  validateParams: func,
  dispatcherBlockRef: shape({}),
};

MachineRegionsSettings.defaultProps = {
  checkedKeys: null,
  items: [],
  onCheck: null,
  onSelect: null,
  selectedKeys: [],
  plainItemsIndex: new Map(),
  expandAll: true,
  expandKeys: [],
  dispatcherWorkRecord: {
    showWorkRecord: false,
    initialSeconds: null,
    durationApproachingMessage: null,
  },
  saveFilterChanges: () => {},
  validateParams: () => {},
  dispatcherBlockRef: null,
};

export default MachineRegionsSettings;
